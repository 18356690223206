/* This file's content was taken from freertos.org */

/**
 * Button
 */

.fr-button {
    display: flex;
    flex-direction: column;
    align-items: center;
    height: 50px;
    line-height: 46px;
    padding: 0;
    font-size: 18px;
    font-weight: bold;
    border: 0;
    white-space: nowrap;
    box-sizing: border-box;
    border: solid 2px #000;
    text-decoration: none;
}

.fr-button.fr-variant-primary {
    background-color: #000;
    color: #fff;
}

.fr-button.fr-variant-secondary {
    background-color: #fff;
    color: #000;
}

.fr-button-icon-label {
    display: flex;
    flex-direction: row;
    align-items: center;
}

.fr-button-icon-label .fr-icon {
    margin-right: 17px;
}

.fr-button-label {
    text-decoration: none;
}

.fr-button.fr-variant-primary.fr-cta {
    background-color: #247133;
    border-color: #247133;
}

.fr-button.fr-auto-width {
    display: inline-block;
    padding: 0 40px;
}