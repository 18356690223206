/* This file contains styling for the title section */
.title-container {
    padding-top: 100px;
    padding-left: 0%;
    padding-right: 15%;
    margin-left: auto;
    margin-right: auto;
    margin-bottom: 40px;
    height: 300px;
    width: 60%;
    color: #1a3065;
}

.title-container .title-header {
    position: relative;
    max-width: 50%;
    margin-left: 25px;
    text-align: left;
    display: inline-block;
}

h2 {
    font-size: 26px;
    line-height: 30px;
    margin-top: 10px;
}

h3 {
    font-size: 22px;
    line-height: 25px;
    margin-top: 0px;
}

.title-container img {
    max-height: 90%;
    width: 30%;
    max-width: 300px;
    min-width: 20%;
    float: left;
}

h1 {
    margin-bottom: 3px;
}

.title-container p {
    margin-top: 3px;
    margin-bottom: 20px;
}


.connected-text {
    color: green;
    float: right;
    font-size: 24px;
    font-weight: bold;
    height: 30px;
    margin-top: 0px !important;
}

.searching-text {
    color: gray;
    float: right;
    font-size: 24px;
    font-weight: bold;
    height: 30px;
    margin-top: 0px !important;
}

.status-indicator {
    top: -2.5px;
    padding-right: 0px !important;
    height: 30px;
}