/* This file contanis global style, particularly fonts and global link highlighting*/
@font-face {
    font-family: 'AmazonEmber';
    src: url('/src/fonts/amazonember_rg-webfont.woff2') format('woff2'), url('/src/fonts/amazonember_rg-webfont.woff') format('woff');
    font-weight: normal;
    font-style: normal;
}

@font-face {
    font-family: 'AmazonEmberItalic';
    src: url('/src/fonts/amazonember_rgit-webfont.woff2') format('woff2'), url('/src/fonts/amazonember_rgit-webfont.woff') format('woff');
    font-weight: normal;
    font-style: normal;
}

@font-face {
    font-family: 'AmazonEmberBold';
    src: url('/src/fonts/amazonember_bd-webfont.woff2') format('woff2'), url('/src/fonts/amazonember_bd-webfont.woff') format('woff');
    font-weight: normal;
    font-style: normal;
}

@font-face {
    font-family: 'AmazonEmberHeavy';
    src: url('/src/fonts/amazonember_he-webfont.woff2') format('woff2'), url('/src/fonts/amazonember_he-webfont.woff') format('woff');
    font-weight: normal;
    font-style: normal;
}

.App {
    text-align: center;
    font-family: 'AmazonEmber';
    margin-left: auto;
    margin-right: auto;
    text-decoration: none;
}

div.div-top-header {
    position: sticky;
    top: 0;
    z-index: 1002;
}

body, html { 
    font-family: 'AmazonEmber';
    margin-left: auto;
    margin-right: auto;
    text-decoration: none;
    min-width: 1024px;
    position: relative;
}

a {
    color:#1E3061;
    text-decoration: none;
}

hr {
    color: #d5dbdb;
    margin: 10px;
}