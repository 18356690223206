/* colors: see https://polaris.a2z.com/foundation/visual-foundation/design-tokens/ */
.expresslink-footer {
    position: sticky;
    bottom: 0;
    left: 0;
    right: 0;
    /* $color-background-home-header, light mode */
    background-color: #232f3e;
}

.footer-content {
    width: 50%;
    height: 30px;
    margin-left: auto;
    margin-right: auto;
}

.footer-items {
    height: inherit;
    align-items: center;
}

a.footer-link:link {
    color: #d5dbdb !important;
}

a.footer-link:visited {
    color: #d5dbdb !important;
}

a.footer-link:hover {
    /* $color-background-button-primary-hover */
    color: #eb5f07 !important;
}

a.footer-link:active {
    /* $color-background-button-primary-hover */
    color: #eb5f07 !important;
}
