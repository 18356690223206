/* This file's content was taken from freertos.org */

.afr_top_nav {
    width: 80%;
    height: 50px;
    display: flex;
    align-items: flex;
}

.nav-header {
    margin-left: 1.8rem;
}

.main_top_header_cta {
    display: block;
    margin-bottom: 15px;
    margin-left: 38px;
}

.main_top_header_cta .fr-button {
    width: 220px;
    font-size: 16px;
    font-family: AmazonEmberBold, Helvetica Neue, Helvetica, Arial, sans-serif;
    font-weight: normal;
}

#afr_page_header_section {
    margin: 0 auto;
    border-bottom: 1px solid #C8C8C8;
    max-width: 1440px;
}

#afr_page_header {
    display: flex;
    flex-direction: row;
    height: 80px;
}

#afr_page_header .freertos_logo {
    padding-left: 34px;
    display: flex;
    align-items: center;
}

#afr_page_header .freertos_logo img {
    height: 58px;
}


/* Navigation and logo container */

#main_top_header {
    font-size: 14px;
    position: fixed;
    background: white;
    width: 100%;
    top: 0px;
    left: 0;
    max-height: 100px;
    z-index: 50;
}


/* Navigation container */

#afr_top_nav {
    flex-grow: 1;
    padding-right: 28px;
    display: flex;
    justify-content: flex-end;
    align-items: flex-end;
}


/* Container for top-level menu items */

#afr_top_nav .menu-subheader-dropdown-container {
    display: flex;
    justify-content: flex-end;
    align-items: flex-end;
    height: 100%;
}


/* Top-level menu */

#afr_subheader_dropdown {
    list-style: none;
    padding: 0;
    margin: 0;
    display: flex;
    flex-direction: row;
}


/* Top-level menu items */

#afr_subheader_dropdown .afr_nav_item {
    margin-left: 47px;
    position: relative;
}

#afr_subheader_dropdown .afr_nav_item:first-child {
    margin-left: 0;
}


/* Menu / submenu links */

#afr_subheader_dropdown a {
    text-decoration: none;
    color: #000;
}


/* Top-level menu links */

#afr_subheader_dropdown .afr_nav_item>a {
    display: block;
    font-family: AmazonEmberBold, Helvetica Neue, Helvetica, Arial, sans-serif;
    font-size: 14px;
    height: 29px;
    text-transform: uppercase;
    border-bottom: 4px solid transparent;
    margin-bottom: 13px;
    white-space: nowrap;
}


/* Submenu links */

#afr_subheader_dropdown .afr_subheader_dropdown_submenu a {
    display: block;
    font-family: AmazonEmber, Helvetica Neue, Helvetica, Arial, sans-serif;
    line-height: 37px;
    margin-top: 8px;
    letter-spacing: 0.06px;
    white-space: nowrap;
    text-align: left;
    padding: 0 20px;
    flex: 1;
}


/* New icon for LTS Libraries */

.new_icon {
    background-color: black;
    color: white;
    padding: 0px 8px;
    font-size: 12px;
    font-weight: 600;
    line-height: 18px;
    margin-right: 8px;
    text-transform: uppercase;
    display: inline-block;
}


/*
* Subheader
*/


/* Submenu items */

#afr_subheader_dropdown .afr_subheader_dropdown_submenu .afr_submenu_item:hover>a,
#afr_subheader_dropdown .afr_subheader_dropdown_submenu .afr_submenu_item.afr_nav_item_hover>a {
    background-color: #000;
    color: #fff;
}


/* Hover state to display primary and secondary submenus */

#afr_subheader_dropdown .afr_nav_item:hover>.afr_subheader_dropdown_submenu,
#afr_subheader_dropdown .afr_nav_item .afr_submenu_item:hover>.afr_subheader_dropdown_submenu,
#afr_subheader_dropdown .afr_nav_item.afr_nav_item_hover>.afr_subheader_dropdown_submenu,
#afr_subheader_dropdown .afr_nav_item .afr_submenu_item.afr_nav_item_hover>.afr_subheader_dropdown_submenu {
    display: block;
}


/**
  * Highlight active item in menu on hover or when section / template is active
  * N.B. Section menu highight will be wrong if menu order changes
  */

#afr_subheader_dropdown .afr_nav_item:hover>a,
.page-template-default #afr_subheader_dropdown .afr_nav_item:nth-child(1)>a,
.page-template-kernel #afr_subheader_dropdown .afr_nav_item:nth-child(1)>a,
.page-template-libraries #afr_subheader_dropdown .afr_nav_item:nth-child(2)>a,
.page-template-libraries-udp #afr_subheader_dropdown .afr_nav_item:nth-child(2)>a,
.page-template-resources #afr_subheader_dropdown .afr_nav_item:nth-child(3)>a,
.page-template-community #afr_subheader_dropdown .afr_nav_item:nth-child(4)>a,
.post-template-default #afr_subheader_dropdown .afr_nav_item:nth-child(4)>a,
.archive #afr_subheader_dropdown .afr_nav_item:nth-child(4)>a,
.blog #afr_subheader_dropdown .afr_nav_item:nth-child(4)>a,
.page-template-support #afr_subheader_dropdown .afr_nav_item:nth-child(5)>a {
    border-bottom: 4px solid #247133;
}


/* New icon for LTS Libraries */

#afr_subheader_dropdown .afr_subheader_dropdown_submenu .afr_lts_libraries_submenu_item:hover>a .new_icon {
    background-color: white;
    color: black;
}

#afr_subheader_dropdown .afr_subheader_dropdown_submenu {
    display: none;
    position: absolute;
    list-style: none;
    z-index: 3;
    min-width: 195px;
    padding: 0;
    box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.5), 0 2px 4px 0 rgba(0, 0, 0, 0.5);
    background-color: #ffffff;
    top: auto;
    border-top: 1px solid #C8C8C8;
}


/* Arrow icon for primary submenu item with secondary submenu */

#afr_subheader_dropdown .afr_nav_item .afr_submenu_item .awsui-icon {
    display: inline-block;
    transform: rotate(-90deg);
    width: 16px;
    height: 12px;
    margin-right: 20px;
    margin-top: 20px;
    pointer-events: none;
    position: absolute;
    right: 0;
}

#afr_subheader_dropdown .afr_nav_item .afr_submenu_item {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    position: relative;
}

#afr_subheader_dropdown .afr_nav_item .afr_submenu_item .awsui-icon svg {
    stroke-width: 2px;
    pointer-events: none;
    fill: #545b64;
    stroke: #545b64;
}

#afr_subheader_dropdown .afr_nav_item .afr_submenu_item .awsui-icon svg .stroke-linejoin-round {
    stroke-linejoin: round;
}

#afr_subheader_dropdown .afr_nav_item .afr_submenu_item>.afr_subheader_dropdown_submenu {
    box-shadow: 2px 2px 4px 0 rgb(0 0 0 / 25%), 2px 2px 4px 0 rgb(0 0 0 / 25%);
    left: 100%;
    top: 0;
}


/* Arrow icon */

#afr_subheader_dropdown .afr_subheader_dropdown_submenu .afr_submenu_item:hover>.awsui-icon svg,
#afr_subheader_dropdown .afr_subheader_dropdown_submenu .afr_submenu_item.afr_nav_item_hover .awsui-icon svg {
    stroke: #fff;
    fill: #fff;
}