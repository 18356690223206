/* This file's content was taken from freertos.org */

.fr-cards-section {
    min-height: 0px;
    display: flex;
    flex-direction: column;
    width: 100%;
    top: 0px;
    vertical-align: top;
}

#fr-homepage-body .fr-section-title {
    text-align: center;
}

.fr-section-subtitle, a.fr-section-subtitle {
    color:black;
    font-size: 22px;
    font-weight: bold;
    line-height: 25px;
    margin-top: 10px;
    margin-bottom: 10px;
    display: block;
}

.fr-cards-list {
    display: flex;
    flex-direction: row;
    list-style: none;
    padding: 0;
    margin: 10px auto 10px auto;
    width: 100%;
    position: relative;
}

.fr-cards-list li {
    vertical-align: top;
    flex-grow: 1;
    flex-basis: 0;
    margin-right: 16px;
    box-shadow: 0 1px 2px 0 rgba(0, 0, 0, 0.5);
    background-color: #ffffff;
    line-height: 24px;
    width: 100%;
    padding: 20px 20px 20px 30px;
    animation: fadeIn 1s;
}

.fr-cards-list li:last-child {
    margin-right: 0;
}

.fr-cards-section .fr-view-all-button {
    text-align: center;
    padding-top: 30px;
}

/* This was newly added for this project */
.fr-cards-list p {
    width: 100%;
    margin-bottom: 75px;
}
.fr-cards-list .fr-button {
    bottom: 30px;
    font-weight: normal;
    width: 225px;
    float: left;
    position: absolute;
    border: 1px solid black;
}

.fr-cards-list .tutorial .fr-button {
    bottom: 0px;
    right: 20px;
    width: 100px;
    position: absolute;
}

.fr-cards-list .tutorial p {
    width: 85%;
    margin-bottom: 75px;
}

.tutorial {
    position: relative;
    padding-bottom: 80px;
}

.fr-cards-section .fr-homepage-bordered-section
{
    top: 0px;
    vertical-align: top;
}

.fr-cards-list .error-card-text {
    margin-bottom: 10px;
}

.fr-cards-list .error-card {
    box-shadow: none;
    border: 1px solid #E2E2E2;
}

.code-wrapper {
    background-color: #FAFAFA;
    border: 1px solid #E2E2E2;
}

.code-wrapper code {
    width: 100%;
    padding: 10px;
}

.fr-cards-list .tutorial .micro{
    list-style-type: circle;
    margin-left: 40px;
}

.fr-cards-list .tutorial h4 { 
    margin: 0px;
    padding-top: 0px;
    padding-bottom: 5px;
}

.fr-cards-list .tutorial a{
    text-decoration: underline;
}

.fr-cards-list .tutorial .micro li{
    box-shadow: none;
    width: 100%;
    padding: 0px;
}

@keyframes fadeIn {
    0% {opacity:0;}
    100% {opacity:1;}
}

.tutorial hr {
    margin-top: 25px;
    margin-bottom: 25px;
}

.cancel {
    position: absolute;
    bottom: 40px;
    right: 400px;
}

.next-button {
    bottom: 30px;
    right: 30px;
    width: 150px !important;
}

.previous-button {
    bottom: 30px;
    right: 200px;
    width: 150px !important;
}

.error-card-wrapper {
    width: 80% !important;
    left: 5%;
    position: relative;
}

.error-card .icon {
    position: absolute;
    left: 3%;
    margin-right: 10px;
    top: 30px;
}

.radio-wrapper {
    margin-left: auto;
    margin-right: auto;
    width: 150px;
}
  
input[type=radio] {
    font: inherit;
    color: black;
    width: 15px;
    height: 15px;
    border: 0.15px solid black;
    border-radius: 50%;
    /* Add if not using autoprefixer */
    -webkit-appearance: none;
    appearance: none;
    /* For iOS < 15 to remove gradient background */
    background-color: #fff;
    /* Not removed via appearance */
    margin: 0;
    margin: 5px;
    display: inline-block;
}

input[type="radio"]::before {
    content: "";
    width: 0.65em;
    height: 0.65em;
    border-radius: 50%;
    transform: scale(0);
    transition: 120ms transform ease-in-out;
    box-shadow: inset 1em 1em var(--form-control-color);
}

input[type=radio]:checked {
    background-color: black !important;
}