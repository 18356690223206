/* This file styles the utility bar on the right side of the screen */
.rightPane {
    width: 13%;
    min-width: 210px;
    right: 7%;
    top: 100px;
    height: 100%;
    position: absolute;
    align-items: center;
}

.sticky {
    position: sticky;
    top: 100px;
}
.links {
    list-style-type: none;
    width: 100%;
    padding: 0;
    padding-bottom: 10px;
    right: 10px;
    margin: 0px;
}

.links a {
    text-decoration: underline;
}

.links li {
    padding-bottom: 8px;
    padding-left: 0px;
    width: 100%;
    text-align: center;
}

.links li b {
    color: black;
}

.right-pane-buttons .fr-button{
    width: 80%;
    margin-top: 10px;
    margin-bottom: 15px;
    margin-left: 10%;
}

.share-button {
    font-size: 16px !important;
    padding: 5px 5px 15px 15% !important;
    height: 50px !important;
    width: 230px !important;
    margin-right: auto !important;
    margin-left: auto !important;
}

.popover {
    width: 100% !important;
    font-size: 16px !important;
    height: 100px;
    margin: 0px !important;
    z-index: 100;
}