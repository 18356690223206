/* This file's content was taken from freertos.org */

/**
 * Footer Menu
 */

small {
    font-size: 14px;
}

.footer-menu {
    width: 100%;
    margin: 100px 0 50px;
    font-size: 14px;
    text-align: center
}


/* Keep text inside footer items from wrapping */

.footer-menu a {
    display: inline-block;
    white-space: nowrap;
    font-size: 14px;
}

.normal-text {
    padding: '0 0 100';
}