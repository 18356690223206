/* This file contains styling for the wifi configurations tab */
.indent {
    margin-left: 20px;
}

.instruction-list li {
    padding-bottom: 3px;
}

.icon {
    float: left;
}