/* This file controls the styling of the tabs */
.tabs {
    width: 60%;
    padding-left: 12%;
}

.tab-list {
    border-bottom: 1px solid #ccc;
    padding-left: 0;
}

.tab-list-item {
    display: inline-block;
    list-style: none;
    margin-bottom: -1px;
    padding: 0.5rem 0.75rem;
}

.tab-list-active {
    background-color: white;
    border: solid #247133;
    border-width: 0px 0px 1px 0px;
    color: #247133;
    font-weight: bold;
}

.tab-body ul {
    padding-left: 0;
    list-style-type: none;
}

.tab-body li {
    padding-bottom: 10px;
}

.tab-body ul .bullet-points {
    padding-left: 30px;
    list-style-type: disc;
}

.tab-body ul .bullet-points li {
    padding-bottom: 5px;
}

.tab-body-graph ul {
    padding-left: 0;
    list-style-type: none;
}


.tab-body-graph ul button {
    margin-bottom: 5px;
}

.tab-body .fr-button {
    width: 200px;
}

.tab-body-graph {
    min-height: 300px;
}

h3 {
    margin-bottom: 0;
}
