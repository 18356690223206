/* This file contanis global style, particularly fonts and global link highlighting*/
.header {
    position: sticky;
    top: 0;
    z-index: 1002;
}

.content {
    margin-right: auto;
    margin-left: auto;
    max-width: 1180px;
}
