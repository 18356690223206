/* This file contains styling for the grid of apexCharts graphs */
.graphs {
    width: 100%;
    margin-top: 0px;
    border-spacing: 20px !important;
}

.graphs td {
    width: 50%;
    height: flex;
}


.graphs tr {
    min-width: 100%;
}

.graphs .graph {
    border: 1px solid #E2E2E2;
    padding: 10px;
    animation: fadeIn 5s;
}

@keyframes fadeIn {
    0% {opacity:0;}
    100% {opacity:1;}
}
  

table{
    height: auto;
}

.searching-animation {
    margin: 100px 0px;
    padding-left: 43%
}

.sample {
    border: 1px solid grey;
    padding: 5px;
    margin: 15px 0px 15px 0px;
}

code {
    margin: 15px 0px 15px 0px;
}